<mat-card>
  <mat-card-title>
    <button mat-icon-button (click)="onCloseButtonClick()">
      <mat-icon>close</mat-icon>
    </button>
    Change history
  </mat-card-title>
  <mat-card-content>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading$ | async"></mat-progress-bar>

    <table class="log-table">
      <tbody>
        <tr class="border-bottom" *ngFor="let change of changes$ | async">
          <td>
            <mat-icon class="log-icon">{{ change.icon.toString() }}</mat-icon>
          </td>
          <td>
            <div class="setting-change-line" [innerHTML]="change.html"></div>
            <div class="setting-metadata">
              <span class="log-user">{{ change.userName }}</span>
              <span class="log-date">{{ change.date | customdate }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="context$ | async as context">
      <mat-paginator
        *ngIf="page$ | async as page"
        [length]="page.length"
        [pageSize]="page.pageSize"
        [pageIndex]="page.pageIndex"
        (page)="handlePage(context, $event)"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
