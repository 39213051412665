import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../app.state';
import { AuthActions, AuthSelectors } from '../auth';
import { MatDrawerMode, MatSidenavContainer, MatSidenavModule } from '@angular/material/sidenav';
import { breakpointObserverChanged } from '../auth/auth.actions';
import { whenPageVisible } from '../shared/utils/when-page-visible';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { NavComponent } from '../nav/nav.component';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { UserAreaSelectComponent } from '../auth/user-area-select/user-area-select.component';
import { UserComponent } from '../auth/user/user.component';
import { MatCardModule } from '@angular/material/card';
import { AppRoutingModule } from '../app-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { EntityLogsComponent } from '../entity-logs/entity-logs.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NavComponent,
    BreadcrumbsComponent,
    UserAreaSelectComponent,
    UserComponent,
    AppRoutingModule,
    MatDividerModule,
    MatSidenavModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    EntityLogsComponent,
  ],
})
export class MainComponent implements OnInit {
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

  isAuthorized$: Observable<boolean>;
  isSideNavOpened$: Observable<boolean>;
  sideNavMode$: Observable<MatDrawerMode>;
  appName = 'Viggo Console';
  isEntityLogsVisible$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    public breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.isAuthorized$ = this.store.select(AuthSelectors.hasAnyRoleSelector);
    this.isSideNavOpened$ = this.store.select(AuthSelectors.isSideNavOpenedSelector);
    this.sideNavMode$ = this.store.select(AuthSelectors.sideNavModeSelector);
    this.isEntityLogsVisible$ = this.store.select(AuthSelectors.isEntityLogsVisibleSelector);

    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.Handset])
      .pipe(whenPageVisible())
      .subscribe(result => this.store.dispatch(breakpointObserverChanged({ isHandset: result.matches })));
  }

  toggleSidenav() {
    this.store.dispatch(AuthActions.sideNavToggled());
  }
}
