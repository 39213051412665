<div class="nav-component-container">
  <div class="header flex-row flex-center-center" [routerLink]="['/']">
    <img alt="favicon" ngSrc="assets/images/favicon.png" height="48" width="48" />
    <span class="mat-h1">Viggo</span>
  </div>
  <mat-divider></mat-divider>
  <mat-accordion multi="true" hideToggle="true" displayMode="flat">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="isOperatorActive()" *ngIf="isOperatorVisible()">
      <mat-expansion-panel-header>Operator</mat-expansion-panel-header>
      <div class="menu">
        <ul>
          <ng-container *ngFor="let item of operatorMenu()">
            <ng-container *ngTemplateOutlet="menuItemTemplate; context: { item: item }"></ng-container>
          </ng-container>
          <ng-container *ngIf="isLevel1() === false">
            <ng-container *ngFor="let item of operatorLevel1Menu()">
              <ng-container *ngTemplateOutlet="menuItemTemplate; context: { item: item }"></ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isLevel1() === false && isLevel2() === false">
            <ng-container *ngFor="let item of operatorLevel2Menu()">
              <ng-container *ngTemplateOutlet="menuItemTemplate; context: { item: item }"></ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="isSupportAgentActive()" *ngIf="isSupportAgentVisible()">
      <mat-expansion-panel-header>Support agent</mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="menuListTemplate; context: { list: supportAgentMenu() }"></ng-container>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="isB2bAccountManagerActive()"
      *ngIf="isB2bAccountManagerVisible()">
      <mat-expansion-panel-header>B2B account manager</mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="menuListTemplate; context: { list: b2bAccountManagerMenu() }"></ng-container>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel
      #rla="routerLinkActive"
      [@.disabled]="true"
      [routerLinkActiveOptions]="{ exact: true }"
      [class.mat-elevation-z0]="true"
      [expanded]="isFinanceManagerActive()"
      routerLinkActive="active"
      *ngIf="isFinanceManagerVisible()"
      class="no-padding-panel">
      <mat-expansion-panel-header>Finance manager</mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="menuListTemplate; context: { list: financeManagerMenu() }"></ng-container>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
  </mat-accordion>
</div>
<ng-template #menuListTemplate let-list="list">
  <div class="menu">
    <ul>
      <ng-container *ngFor="let item of list">
        <ng-container *ngTemplateOutlet="menuItemTemplate; context: { item: item }"></ng-container>
      </ng-container>
    </ul>
  </div>
</ng-template>
<ng-template #menuItemTemplate let-item="item">
  <li>
    <a [routerLink]="item.route" routerLinkActive="active-link" class="icon-text">
      <i class="material-icons">{{ item.icon }}</i>
      {{ item.name }}
    </a>
  </li>
</ng-template>
