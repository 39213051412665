import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions } from '.';

export interface AuthState {
  customToken: string;
  signedInAsSupportAgent: boolean;
  supportAgentUserToken: string;
}

export const initialState: AuthState = {
  customToken: null,
  signedInAsSupportAgent: false,
  supportAgentUserToken: null,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.supportAgentUserCustomTokenFetched, (state, action) => ({
    ...state,
    customToken: action.customToken.token,
  })),
  on(AuthActions.supportAgentUserTokenChanged, (state, action) => ({
    ...state,
    supportAgentUserToken: action.supportAgentUserToken,
  })),
  on(AuthActions.signedInAsSupportAgent, state => ({
    ...state,
    signedInAsSupportAgent: true,
  })),
);

export function reducer(state: AuthState, action: Action) {
  return authReducer(state, action);
}
