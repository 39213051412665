import { createReducer, on, Action } from '@ngrx/store';
import * as EntityLogsActions from './entity-logs.actions';
import { EntityDelta } from '../../shared/models/entity-delta.model';
import { PageEvent } from '@angular/material/paginator';
import { Context } from './entity-logs.actions';

export interface EntityLogsState {
  isEntityChangeLogOpened: boolean;
  context: Context;
  entityChanges?: EntityDelta[];
  loading: boolean;
  page: PageEvent;
}

export const initialState: EntityLogsState = {
  isEntityChangeLogOpened: false,
  context: null,
  entityChanges: [],
  loading: false,
  page: {
    pageIndex: 0,
    pageSize: 10,
    previousPageIndex: 0,
    length: 1,
  },
};

export const reducer = createReducer(
  initialState,
  on(EntityLogsActions.entityChangeLogOpened, (state, { context }) => ({
    ...state,
    loading: true,
    isEntityChangeLogOpened: true,
    context: context,
  })),
  on(EntityLogsActions.entityChangesFetched, (state, { deltas, total }) => ({
    ...state,
    entityChanges: deltas,
    loading: false,
    page: {
      ...state.page,
      length: total,
    },
  })),
  on(EntityLogsActions.entityChangeLogClosed, state => ({
    ...state,
    entityChanges: [],
    isEntityChangeLogOpened: false,
    context: null,
  })),
  on(EntityLogsActions.entityChangeLogPageChanged, (state, { context, page }) => ({
    ...state,
    entityChanges: [],
    loading: true,
    context: context,
    page: page,
  })),
);

export function entityLogsReducer(state: EntityLogsState | undefined, action: Action) {
  return reducer(state, action);
}
