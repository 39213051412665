import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from '../app-routing.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { SharedModule } from '../shared/shared.module';
import { DateFormatEnum } from '../shared/pipes/custom-date.pipe';
import { Observable } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { EntityChangesActions, EntityChangesSelector } from './store';
import { EntityDeltaVM } from '../shared/models/entity-delta.vm.model';
import { MatButton, MatIconButton } from '@angular/material/button';
import { Context } from './store/entity-logs.actions';

@Component({
  selector: 'app-entity-logs',
  templateUrl: './entity-logs.component.html',
  styleUrls: ['./entity-logs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    AppRoutingModule,
    MatExpansionModule,
    MatDividerModule,
    NgOptimizedImage,
    MatCardModule,
    MatPaginatorModule,
    SharedModule,
    MatProgressBarModule,
    MatButton,
    MatIconButton,
  ],
})
export class EntityLogsComponent implements OnInit {
  changes$: Observable<EntityDeltaVM[]>;
  page$: Observable<PageEvent>;
  isLoading$: Observable<boolean>;
  context$: Observable<Context>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(EntityChangesSelector.selectLoading);
    this.context$ = this.store.select(EntityChangesSelector.selectContext);
    this.changes$ = this.store.select(EntityChangesSelector.selectEntityChangeVMs);
    this.page$ = this.store.select(EntityChangesSelector.selectEntityChangePage);
  }

  handlePage(context: Context, event: PageEvent): void {
    this.store.dispatch(EntityChangesActions.entityChangeLogPageChanged({ context: context, page: event }));
  }

  onCloseButtonClick(): void {
    this.store.dispatch(EntityChangesActions.entityChangeLogClosed());
  }

  protected readonly DateFormatEnum = DateFormatEnum;
}
